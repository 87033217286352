import React from 'react';
import Divider from '../components/Section/Divider';

const RegisterSection = () => (
  <>
    <Divider />
    <section className="section section-color">
      <div className="container" id="register">
        <h2>Deixe de pagar multas agora mesmo</h2>
        <p>Cadastre-se sem compromisso e seja surpreendido com a facilidade de nosso app</p>

        <div style={{maxWidth: '500px'}}>
          <form method="post" action="/register">
            <div style={{display: 'flex', alignItems: 'center'}}>
              <input
                className="form-control"
                name="email"
                placeholder="Digite seu e-mail" />

              <button className="btn btn-success"
                type="submit"
                style={{marginLeft: '5px'}}>
                Cadastre-se
                <small>Grátis</small>
              </button>
            </div>
            <p>E receba 15 dias de premium</p>
          </form>
        </div>
      </div>
    </section>
  </>
)

export default RegisterSection;