import React from "react"
import PropTypes from "prop-types"
import Footer from './footer';
import Navbar from './navbar';
import RegisterSection from '../sections/register';
import config from "../config";

const HeaderCta = () => (
  <div className="header-cta">
    <div className="header-cta-container animated slideInDown">
      <h2 className="header-cta-title">
        Se livre das multas tributárias
      </h2>
      <span>
        <p>Agora você também não precisa se preocupar com mutas tributárias!
        Com o SEFAZ Fácil Brasil ficou simples realizar seus cáculas tributários e levar uma vida livre de multas.</p>

        <p>Você recebará discas e informações que o adurarão a não cometer erros.
        </p>
      </span>

      <a href={config.endpoints.app} className="btn btn-success">
        Cadastre-se já
        <small>Grátis</small>
      </a>
    </div>
  </div>
)

const Layout = ({ open, children }) => {
  return (
    <>
      <link href="/public/vendor/fonts.css" rel="stylesheet"/>

      <link href="/public/vendor/nucleo.css" rel="stylesheet"/>

      <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"/>    
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css"/>

      <link type="text/css" href="/public/argon.min.css" rel="stylesheet"/>    
      
      <link type="text/css" href="/public/docs.min.css" rel="stylesheet"/>
      <link href="/public/css/fonts.css" rel="stylesheet"/>
      <link href="/public/css/main.css" rel="stylesheet"/>

      <Navbar />

      <main className={`profile-page ${ open ? '' : 'section-closed'}`}>
        <section className="section-profile-cover section-shaped my-0">
          { open ? <HeaderCta /> : '' }
          <div className="separator separator-bottom separator-skew">
            <svg x="0" y="0" viewBox="0 0 2560 60" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <polygon className="fill-white" points="2560 0 2560 60 0 60"></polygon>
            </svg>
          </div>
        </section>

        {children}

        <RegisterSection />
      </main>
      <Footer />      
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool
}

export default Layout
