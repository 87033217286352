import React from 'react';
import LogoWhite from '../images/logo-white.png';
import Logo from '../images/logo-color.png';
import config from '../config.js';
import { Link } from 'gatsby';

const LinkText = ({ href, title }) => {
  return (
    <li className="nav-item dropdown">
      <Link to={href} className="nav-link" role="button">
        <span className="nav-link-inner--text">{ title }</span>
      </Link>
    </li>
  )
}

const LinkIcon = ({ title, href, icon }) => {
  return (
    <li className="nav-item">
      <a className="nav-link nav-link-icon" href={href} target="_blank" rel="noopener noreferrer" data-toggle="tooltip" title="" data-original-title={`Siga o nosso ${title}`}>
        <i className={icon}></i>
        <span className="nav-link-inner--text d-lg-none">{title}</span>
      </a>
    </li>
  )
}

const ButtonSignIn = () => {
  return (
    <li className="nav-item d-none d-lg-block ml-lg-4">
      <a href={config.endpoints.app} target="_blank" className="btn btn-neutral btn-icon">
        <span className="btn-inner--icon">
          <i className="fa fa-user mr-2"></i>
        </span>
        <span className="nav-link-inner--text">Login</span>
      </a>
    </li>
  )
}

const Navbar = () => (
  <nav id="navbar-main" className="navbar navbar-main navbar-expand-lg navbar-transparent navbar-light headroom headroom--top headroom--bottom">
    <div className="container">
      <a className="navbar-brand mr-lg-5 animated pulse" href="/">
        <img src={LogoWhite} alt="SEFAZ Fácil Brasil" />
      </a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="navbar-collapse collapse" id="navbar_global">
        <div className="navbar-collapse-header">
          <div className="row">
            <div className="col-6 collapse-brand">
              <a href="/">
                <img src={Logo} alt="SEFAZ Fácil Brasil" />
              </a>
            </div>
            <div className="col-6 collapse-close">
              <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation">
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>

        <ul className="navbar-nav navbar-nav-hover align-items-lg-center">
          {
            config.navbar.map(({ title, href }) => (
              <LinkText
                title={title}
                href={href}
              />
            ))
          }
        </ul>

        <ul className="navbar-nav align-items-lg-center ml-lg-auto">
          {
            config.social.map(({ title, icon, href }) => {
              return (
                <LinkIcon
                  title={title}
                  icon={icon}
                  href={href}
                />
              )
            })
          }

          <ButtonSignIn />
        </ul>
      </div>
    </div>
  </nav>
);

export default Navbar;