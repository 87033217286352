const HOST = 'sefazfacilbrasil.com.br';
const HOTSITE_URL = `https://www.${HOST}`;
const APP_URL = `https://app.${HOST}`;

export default {
  "endpoints": {
    "hotsite": HOTSITE_URL,
    "app": APP_URL
  },
  "download": {
    "android": "https://play.google.com/store/apps/details?id=io.ionic.sefazfacil&hl=pt_BR",
    "ios": "https://apps.apple.com/br/app/sefaz-f%C3%A1cil-brasil-v2/id1453120439"
  },
  "navbar": [
    {
      "title": "Sobre nós",
      "href": "/sobre"
    },
    {
      "title": "Planos",
      "href": "/planos"
    },
    {
      "title": "Contato",
      "href": "/contato"
    },
    {
      "title": "Download",
      "href": "/#download"
    }    
  ],
  "footer": [
    {
      "title": "Home",
      "href": "/"
    },
    {
      "title": "Sobre nós",
      "href": "/sobre"
    },
    {
      "title": "Contato",
      "href": "/contato"
    },
    {
      "title": "Web App",
      "href": "https://app.sefazfacilbrasil.com.br"
    }    
  ],
  "social": [
    {
      "title": "Facebook",
      "icon": "fa fa-facebook-square",
      "href": "https://www.facebook.com/sefazfacilbrasil",
      "cls": "facebook"
    },
    {
      "title": "Instagram",
      "icon": "fa fa-instagram",
      "href": "https://www.instagram.com/sefazfacilbrasil",
      "cls": "instagram"
    },
    {
      "title": "Twitter",
      "icon": "fa fa-twitter-square",
      "href": "https://twitter.com/sefazfacilbrasil",
      "cls": "twitter"
    }
  ]
}