import React from 'react';
import PropTypes from 'prop-types';

const Divider = ({ background, fill }) => {
  return (
    <section className="section-separator" style={{height: '60px', overflow: 'hidden'}}>
      <div className="separator separator-custom separator-skew separator-colorful">
        <svg x="0" y="0" style={{width: '100%', height: '100%', border: '0px', backgroundColor: background}} viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon fill={fill} class="fill-blue" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </section>
  )
}

Divider.defaultProps = {
  background: '#ffffff',
  fill: '#2e4a96'
}

Divider.propTypes = {
  background: PropTypes.string,
  fill: PropTypes.string
}

export default Divider;