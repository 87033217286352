import React from 'react';
import PropTypes from 'prop-types';

import Divider from '../Divider';

var colors = {
  normal: {
    background: '#2e4a96',
    fill: '#ffffff'
  },
  reverse: {
    background: '#ffffff',
    fill: '#2e4a96'
  }  
}

const Box = ({ reverse, divider, children }) => {
  var color = reverse ? colors.reverse : colors.normal;

  return (
    <>
      { divider ? <Divider background={color.background} fill={color.fill} /> : '' }
      <section className={reverse ? 'section section-color' : 'section'}>{children}</section>
    </>    
  )
}

Box.defaultProps = {
  reverse: false,
  divider: false
}

Box.propTypes = {
  reverse: PropTypes.bool,
  divider: PropTypes.bool
}

export default Box;