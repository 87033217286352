import React from 'react'
import { Link } from 'gatsby';
import config from '../config.js';

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="row row-grid align-items-center mb-5">
        <div className="col-lg-6">
          <h3 className="text-primary font-weight-light mb-2">SEFAZ Fácil Brasil</h3>
          <h4 className="mb-0 font-weight-light">O melhor jeito de se livrar das multas.</h4>
        </div>
        <div className="col-lg-6 text-lg-center btn-wrapper">
          {
            config.social.map(({icon, title, href, cls }) => (
              <a target="_blank" href={href} className={`btn btn-neutral btn-icon-only btn-${cls} btn-round btn-lg`} data-toggle="tooltip" data-original-title={`Siga nosso ${title}`}>
                <i className={icon}></i>
              </a>
            ))
          }
        </div>
      </div>
      <hr/>
      <div className="row align-items-center justify-content-md-between">
        <div className="col-md-6">
          <div className="copyright">
            ©{ new Date().getFullYear() }
            {` `}
            <a href="/" target="_blank">SEFAZ Fácil Brasil</a>.
          </div>
        </div>
        <div className="col-md-6">
          <ul className="nav nav-footer justify-content-end">
            <li className="nav-item">
              <Link to="/" className="nav-link" target="_blank">Home</Link>
            </li>
            <li className="nav-item">
              <Link to="/sobre" className="nav-link" target="_blank">Sobre nós</Link>
            </li>
            <li className="nav-item">
              <Link to="/contato" className="nav-link" target="_blank">Contato</Link>
            </li>
            <li className="nav-item">
              <a href={config.endpoints.app} className="nav-link" target="_blank">Web App</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer;